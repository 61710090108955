<template>
    <div class="compon_echarts">
      <div class="compon_echarts_enterprise" ref="echarts"></div>
    </div>
  </template>
  
  <script>
  // 引入echarts
  import * as echarts from 'echarts'
  
  export default {
    name: 'ComponEcharts',
    props: {
      companyList: {
        type: Array,
        default: () => []
      }, // 数据
      businessChatParams: {
        type: Object,
        default: () => {}
      }
    },
    watch: {
      companyList: {
        deep: true, // 深度监听数组变化
        handler(newItems, oldItems) {
          const myChart = echarts.init(this.$refs.echarts)
          myChart.dispose()
          this.renderEcharts()
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.renderEcharts()
      });
    },
  
    methods: {
      renderEcharts() {
        const myChart = echarts.init(this.$refs.echarts)
      
        let option
        option = {
          // 可以显示整个图表
        //   dataZoom: [
        //     {
        //       type: 'slider', // inside 内置型  slider 滑动条
        //       show: true,
        //       start: 50,
        //       bottom: '-1%',
        //       end: 100
        //     },
        //     {
        //       type: 'inside',
        //       xAxisIndex: 0,
        //       start: 50,
        //       end: 100
        //     }
        //   ],
          title: {
            subtext: '（业务数据量/个）', // 副标题文本
            left: 'left', // 标题水平居中
            top: 14,
            textStyle: {
              // 标题文本样式
            },
            subtextStyle: {
              // 副标题样式
              fontStyle: 'normal', // 字体风格
              fontWeight: 'bold', // 字体粗细
              fontSize: 12, // 字体大小，可以根据需要调整
              color: '#333333' // 字体颜色
            }
          },
          color: '#00BDFF',
          // 实现 echarts 鼠标悬浮上去的效果
          tooltip: {
            trigger: 'axis',
            show: true, // 是否显示提示框，默认为 true
            // trigger: 'item', // 触发类型，可选值: 'item'（数据项触发），'axis'（坐标轴触发），'none'（不触发）
            backgroundColor: '#fff', // 提示框背景色
            padding: [3], // 内边距
            textStyle: {
              // 文本样式
              color: '#fff', // 文本颜色
              fontSize: 12 // 文本字号
            },
            // 悬浮框提示
            // formatter(params) {
            //   const userData = chartArr[params[0].dataIndex]
  
            //   // 处理时间展示格式
            //   return `
            //     <div style="width:200px; height:68px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
            //       <div style="display:flex; margin-bottom: 4px">
            //          <img width="32" height="32" src="${params[0].data.img}" alt="" />
  
            //         <div style="display:flex; flex-direction: column; justify-content: space-between; margin-left: 8px">
            //           <span style="font-size: 10px; color: #333; font-weight: 600;">${userData.companyFullName}</span>
            //           <span style="font-size: 10px; color: #333; font-weight: 400;">${userData.number}</span>
            //         </div>
            //       </div>
  
            //     </div>`
            // },
            // 辅助线
            axisPointer: {
              type: 'line', // 指示器类型，可选值: 'line'（直线指示器），'shadow'（阴影指示器），'cross'（十字准星指示器）
              lineStyle: {
                color: '#1890FF', // 辅助线颜色
                type: 'dashed'
              },
              crossStyle: {
                // 十字准星指示器样式设置
                color: '#aaa' // 线条颜色
              },
              shadowStyle: {
                // 阴影指示器样式设置
                color: 'rgba(150,150,150,0.3)' // 阴影颜色
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              //y轴线的配置
              show: true, //是否展示
              lineStyle: {
                color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                width: 1, //y轴线的宽度
                type: 'dashed' //y轴线为实线
              }
            },
            axisLabel: {
              interval: 0, // 强制显示所有标签
              textStyle: {
                color: '#333333' //Y轴内容文字颜色
              },
              formatter: function (value) {
                const maxLength = 8 // 每行显示的最大字符数
                let result = ''
                for (let i = 0; i < value.length; i += maxLength) {
                  result += value.substring(i, i + maxLength) + '\n'
                }
                return result
              }
            },
            // axisLabel: {
            //   //y轴文字的配置
            //   width: 100, // 设置标签的最大宽度
            //   overflow: 'breakAll', // 自动换行
            //   textStyle: {
            //     color: '#333333' //Y轴内容文字颜色
            //   },
            //   axisLabel: {
            //     formatter: function (value) {
            //       const maxLength = 8 // 每行显示的最大字符数
            //       let result = ''
            //       for (let i = 0; i < value.length; i += maxLength) {
            //         result += value.substring(i, i + maxLength) + '\n'
            //       }
            //       return result
            //     }
            //   },
            //   interval: 0 // 设置为0表示全部显示x轴坐标
            // },
            // boundaryGap: false,
            nameTextStyle: {
              verticalAlign: 'bottom',
              lineHeight: 10,
              backgroundColor: '#DCE9FE'
            },
            data: ['a','b','c','d']
          },
          yAxis: {
            type: 'value',
            // data: [0, 2, 4, 6, 16000, 20000, 24000],
            axisLine: {
              //y轴线的配置
              show: true, //是否展示
              lineStyle: {
                color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
                width: 1, //y轴线的宽度
                type: 'dashed' //y轴线为实线
              }
            },
            axisLabel: {
              //y轴文字的配置
              textStyle: {
                interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
                color: '#333333', //Y轴内容文字颜色
                fontSize: 10 // 设置y轴标题文字大小为25
              }
            },
            // y轴分割线
            splitLine: {
              show: true, // 显示分隔线
              lineStyle: {
                type: 'dashed', // 设置分隔线为虚线
                color: '#E4E4E4'
              }
            },
           
          },
          series: [
          {
              data: [1,2,3,4],
              type: 'line',
              barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
              barMaxWidth: '50px', // 限制柱子的最大宽度
              color: '#4ce6bb',
  
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#4ce6bb' }, // 渐变起始颜色
                  { offset: 1, color: 'rgba(255, 255, 255, 0)' } // 渐变结束颜色（透明）
                ])
  
                //   color: el.color
              },
              markPoint: {
                symbol: 'circle' // 设置拐点小圆点
                // 其他配置项...
              },
              symbolSize: 0 // 设置拐点小圆点大小
            },
            {
              data: [2,3,4,5],
              type: 'line',
              barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
              barMaxWidth: '50px', // 限制柱子的最大宽度
              color: '#2869fd',
  
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2869fd' }, // 渐变起始颜色
                  { offset: 1, color: 'rgba(255, 255, 255, 0)' } // 渐变结束颜色（透明）
                ])
  
                //   color: el.color
              },
              markPoint: {
                symbol: 'circle' // 设置拐点小圆点
                // 其他配置项...
              },
              symbolSize: 0 // 设置拐点小圆点大小
            },
            {
              data: [3,4,5,6],
              type: 'line',
              barWidth: '20px', // 可以是具体像素值 '20' 或百分比 '50%'
              barMaxWidth: '50px', // 限制柱子的最大宽度
              color: '#fe5c8a',
  
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#fe5c8a' }, // 渐变起始颜色
                  { offset: 1, color: 'rgba(255, 255, 255, 0)' } // 渐变结束颜色（透明）
                ])
  
                //   color: el.color
              },
              markPoint: {
                symbol: 'circle' // 设置拐点小圆点
                // 其他配置项...
              },
              symbolSize: 0 // 设置拐点小圆点大小
            }
          ]
        }
        option && myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    }
  }
  </script>
  
  
  <style scoped lang="less">
  .compon_echarts {
    width: 100%;
    overflow: hidden;
  
    .compon_echarts_enterprise {
      height: 340px;
      overflow: hidden;
      width: 100%;
     
    }
  }
  </style>
  