<template>
  <div class="three_cai">
    <div class="dataTxt">
      <div class="ul_flex">
        <div class="ul_flex_center">
          <div @click="vitisClick()" class="li_two">
            <div class="data_info">
              <span class="title">合作服务商（个）</span>
              <div class="numInfo">
                <span class="num">{{
                  cockpitData.visitDataCount == null ? 0 : cockpitData.visitDataCount
                }}</span>

                <span class="left_margin">
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.visitWeekNum == null ? 0 : cockpitData.visitWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="icon">
              <img class="icon_img" src="../../../../static/images/shengtai1.png" alt="" />
            </div>
          </div>

          <div class="bottom_flex">
            <div class="li_two_bottom">
              <div class="data_info">
                <span class="title">拓展中服务商（个）</span>
                <div class="numInfo">
                  <span class="num">{{
                    cockpitData.visitDemandCount == null ? 0 : cockpitData.visitDemandCount
                  }}</span>

                  <span class="left_margin">
                    <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.visitRequirementWeekNum == null
                        ? 0
                        : cockpitData.visitRequirementWeekNum
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="icon">
                <img class="icon_img" src="../../../../static/images/shengtai2.png" alt="" />
              </div>
            </div>
            <div class="li_two_bottom">
              <div class="data_info">
                <span class="title">待分配服务商（个）</span>
                <div class="numInfo">
                  <span class="num color6">{{
                    cockpitData.totalBudget == null ? 0 : cockpitData.totalBudget
                  }}</span>

                  <span class="left_margin">
                    <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.weekBudget == null ? 0 : cockpitData.weekBudget
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="icon">
                <img class="icon_img" src="../../../../static/images/shengtai3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="ul_flex_up">
          <div @click="companyClick('3')" class="li_center" style="margin-bottom: 0px">
            <div class="icon">
              <img class="icon_img" src="../../../../static/images/shengtai7.png" alt="" />
            </div>
            <div class="data_info">
              <span class="title">合作专家</span>
              <div class="numInfo">
                <span class="num">{{
                  cockpitData.potentialLeadsCount == null ? 0 : cockpitData.potentialLeadsCount
                }}</span>

                <span class="left_margin">
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.weekBudget == null ? 0 : cockpitData.weekBudget
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="ul_flex_center">
          <div @click="vitisClick()" class="li_two">
            <div class="data_info">
              <span class="title">真实商机（个）</span>
              <div class="numInfo">
                <span class="num">{{
                  cockpitData.visitDataCount == null ? 0 : cockpitData.visitDataCount
                }}</span>

                <span class="left_margin">
                  <span class="add">本周新增</span>
                  <span class="addNum">{{
                    cockpitData.visitWeekNum == null ? 0 : cockpitData.visitWeekNum
                  }}</span>
                  <span class="addIcon">
                    <i class="el-icon-top"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="icon">
              <img class="icon_img" src="../../../../static/images/shengtai4.png" alt="" />
            </div>
          </div>

          <div class="bottom_flex">
            <div class="li_two_bottom">
              <div class="data_info">
                <span class="title">潜在线索（个）</span>
                <div class="numInfo">
                  <span class="num color5">{{
                    cockpitData.visitDemandCount == null ? 0 : cockpitData.visitDemandCount
                  }}</span>

                  <span class="left_margin">
                    <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.visitRequirementWeekNum == null
                        ? 0
                        : cockpitData.visitRequirementWeekNum
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="icon">
                <img class="icon_img" src="../../../../static/images/shengtai5.png" alt="" />
              </div>
            </div>
            <div class="li_two_bottom">
              <div class="data_info">
                <span class="title">潜在客户（个）</span>
                <div class="numInfo">
                  <span class="num color6">{{
                    cockpitData.totalBudget == null ? 0 : cockpitData.totalBudget
                  }}</span>

                  <span class="left_margin">
                    <span class="add">本周新增</span>
                    <span class="addNum">{{
                      cockpitData.weekBudget == null ? 0 : cockpitData.weekBudget
                    }}</span>
                    <span class="addIcon">
                      <i class="el-icon-top"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="icon">
                <img class="icon_img" src="../../../../static/images/shengtai6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center_chart">
      <div class="center_chart_item">
        <Industrychart></Industrychart>
      </div>
      <div class="center_chart_item">
        <chinachart></chinachart>
      </div>
      <div class="center_chart_item">
        <distributionchart></distributionchart>
      </div>
      <div class="center_chart_item" style="margin-right: 0px">
        <classChart></classChart>
      </div>
    </div>
    <div class="center_chart_bottom">
      <div class="center_chart_bottom_item">
        <div class="tab_flex">
          <div class="left_title">
            <span>潜在线索数据趋势</span>
          </div>
          <div class="right_tab">
            <div class="item_date" :class="tanindex == 1 ? 'back' : ''">
              <span>周</span>
            </div>
            <div class="item_date">
              <span>近30天</span>
            </div>
          </div>
        </div>
        <clueChart></clueChart>
      </div>
      <div class="center_chart_bottom_item">
        <div class="data_echarts_ranging">
        <div class="data_echarts_ranging">
          <div class="data_echarts_ranging_top">
            <div class="data_echarts_ranging_top_title">
              <span>走访榜单TOP</span>
              <img src="../../../assets/image/workbench/ranging.png" />
            </div>
            <div class="data_echarts_ranging_top_item">
              <div class="data_echarts_ranging_top_item_more" @click="seeMore(0)">查看更多 >></div>
              <div class="data_echarts_ranging_top_item_date">
                <div
                  class="day"
                  @click="Clickranging(1)"
                  :class="rangingActive == '1' ? 'date_active' : ''"
                >
                  天
                </div>
                <div
                  class="week"
                  @click="Clickranging(2)"
                  :class="rangingActive == '2' ? 'date_active' : ''"
                >
                  周
                </div>
                <div
                  class="mouth"
                  @click="Clickranging(3)"
                  :class="rangingActive == '3' ? 'date_active' : ''"
                >
                  月
                </div>
              </div>
            </div>
          </div>
          <div class="data_echarts_ranging_bottom">
            <div class="top_three" v-if="rangingTable.length == 3">
              <div class="top_three_item two">
                <img src="../../../assets/image/workbench/ranging2.png" alt="" class="img1" />
                <img
                  v-if="rangingTable[1].customerHead"
                  :src="rangingTable[1].customerHead"
                  alt=""
                  class="profile"
                />
                <!-- <img
                  v-else
                  src="../../../assets/image/wait-set-manager.png"
                  alt=""
                  class="profile"
                /> -->
                <img src="../../../assets/image/workbench/num2.png" alt="" class="ranging" />
                <h2>{{ rangingTable[1].customerName }}</h2>
                <div class="item_list">
                  <span class="left">走访次数</span>
                  <span class="right"
                    ><em>{{ rangingTable[1].visitNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">走访需求</span>
                  <span class="right"
                    ><em>{{ rangingTable[1].visiRequirementNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">最近走访时间 </span>
                  <span class="right">{{ rangingTable[1].time }}</span>
                </div>
              </div>
              <div class="top_three_item one">
                <img src="../../../assets/image/workbench/ranging1.png" alt="" class="img1" />
                <img
                  v-if="rangingTable[0].customerHead"
                  :src="rangingTable[0].customerHead"
                  alt=""
                  class="profile"
                />
                <!-- <img
                  v-else
                  src="../../../assets/image/wait-set-manager.png"
                  alt=""
                  class="profile"
                /> -->
                <img src="../../../assets/image/workbench/num1.png" alt="" class="ranging" />
                <h2>{{ rangingTable[0].customerName }}</h2>
                <div class="item_list">
                  <span class="left">走访次数</span>
                  <span class="right"
                    ><em>{{ rangingTable[0].visitNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">走访需求</span>
                  <span class="right"
                    ><em>{{ rangingTable[0].visiRequirementNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">最近走访时间 </span>
                  <span class="right">{{ rangingTable[0].time }}</span>
                </div>
              </div>
              <div class="top_three_item three">
                <img src="../../../assets/image/workbench/ranging3.png" alt="" class="img1" />
                <img
                  class="profile"
                  v-if="rangingTable[2].sex == 0"
                  src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png"
                  alt=""
                />
                <img
                  class="profile"
                  v-else
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png"
                  alt=""
                />
                <img src="../../../assets/image/workbench/num3.png" alt="" class="ranging" />
                <h2>{{ rangingTable[2].customerName }}</h2>
                <div class="item_list">
                  <span class="left">走访次数</span>
                  <span class="right"
                    ><em>{{ rangingTable[2].visitNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">走访需求</span>
                  <span class="right"
                    ><em>{{ rangingTable[2].visiRequirementNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">最近走访时间 </span>
                  <span class="right">{{ rangingTable[2].time }}</span>
                </div>
              </div>
            </div>
            <div class="top_three" v-if="rangingTable.length == 2">
              <div class="top_three_item two">
                <img src="../../../assets/image/workbench/ranging2.png" alt="" class="img1" />
                <img
                  class="profile"
                  v-if="rangingTable[1].sex == 0"
                  src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png"
                  alt=""
                />
                <img
                  class="profile"
                  v-else
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png"
                  alt=""
                />
                <img src="../../../assets/image/workbench/num2.png" alt="" class="ranging" />
                <h2>{{ rangingTable[1].customerName }}</h2>
                <div class="item_list">
                  <span class="left">走访次数</span>
                  <span class="right"
                    ><em>{{ rangingTable[1].visitNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">走访需求</span>
                  <span class="right"
                    ><em>{{ rangingTable[1].visiRequirementNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">最近走访时间 </span>
                  <span class="right">{{ rangingTable[1].time }}</span>
                </div>
              </div>
              <div class="top_three_item one">
                <img src="../../../assets/image/workbench/ranging1.png" alt="" class="img1" />
                <img
                  class="profile"
                  v-if="rangingTable[0].sex == 0"
                  src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png"
                  alt=""
                />
                <img
                  class="profile"
                  v-else
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png"
                  alt=""
                />
                <img src="../../../assets/image/workbench/num1.png" alt="" class="ranging" />
                <h2>{{ rangingTable[0].customerName }}</h2>
                <div class="item_list">
                  <span class="left">走访次数</span>
                  <span class="right"
                    ><em>{{ rangingTable[0].visitNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">走访需求</span>
                  <span class="right"
                    ><em>{{ rangingTable[0].visiRequirementNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">最近走访时间 </span>
                  <span class="right">{{ rangingTable[0].time }}</span>
                </div>
              </div>
            </div>
            <div class="top_three" v-if="rangingTable.length == 1">
              <div class="top_three_item one">
                <img src="../../../assets/image/workbench/ranging1.png" alt="" class="img1" />
                <img
                  class="profile"
                  v-if="rangingTable[0].sex == 0"
                  src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png"
                  alt=""
                />
                <img
                  class="profile"
                  v-else
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png"
                  alt=""
                />

                <img src="../../../assets/image/workbench/num1.png" alt="" class="ranging" />
                <h2>{{ rangingTable[0].customerName }}</h2>
                <div class="item_list">
                  <span class="left">走访次数</span>
                  <span class="right"
                    ><em>{{ rangingTable[0].visitNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">走访需求</span>
                  <span class="right"
                    ><em>{{ rangingTable[0].visiRequirementNum }}</em
                    >次</span
                  >
                </div>
                <div class="item_list">
                  <span class="left">最近走访时间 </span>
                  <span class="right">{{ rangingTable[0].time }}</span>
                </div>
              </div>
            </div>
            <div class="data_echarts_ranging_table">
              <el-table
                :data="rangTable"
                :header-cell-style="{ color: '#333333', 'font-size': '14px', 'font-weight': '400' }"
              >
                <el-table-column type="index" label="排名" align="center" width="60">
                  <template v-slot="{ row }">
                    <span>{{ row.index }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="头像" align="center" width="60">
                  <template v-slot="{ row }">
                    <img
                      class="img_crie"
                      v-if="row.sex == 0"
                      src="https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png"
                      alt=""
                    />
                    <img
                      class="img_crie"
                      v-else
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-419447711Group%203779.png"
                      alt=""
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="customerName" label="姓名" align="center" width="120">
                  <template v-slot="{ row }">
                    <span>{{ row.customerName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="visitNum"
                  label="走访次数"
                  align="center"
                  sortable
                ></el-table-column>
                <el-table-column
                  prop="visiRequirementNum"
                  label="走访需求"
                  align="center"
                  sortable
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="time"
                  label="最近走访时间"
                  align="center"
                  sortable
                  width="160"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      </div>
    
    </div>
  </div>
</template>
      
      <script>
import Industrychart from '../components/Industrychart.vue'
import distributionchart from '../components/distributionchart.vue'
import classChart from '../components/classChart.vue'
import chinachart from '../components/chinachart.vue'
import clueChart from '../components/clueChart.vue'
export default {
  components: { Industrychart, distributionchart, classChart, chinachart, clueChart },
  props: {},
  data() {
    return {
      queryInfo: {
        dataType: 1,
        id: 0,
        timeType: 2
      },
      tanindex: 1,
      cockpitData: {},
      checkList: [],
      dateIndex: 1,
      dates: [
        {
          name: '本月'
        },
        {
          name: '本年'
        }
      ],
      rangingTable:[
        {

        },
        {

        },
        {

        }
      ],
      chartInfo: {
        nameEchart: '（客户数/人）',
        chartType: 'line',
        type: 1,
        chartArr: [],
        img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-227242483Group 1142815023.png'
      },
      tabindex: 0,

      tabList: [
        {
          name: '客户数据',
          txt: '（客户数/人）',
          type: 1,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-227242483Group 1142815023.png'
        },
        {
          name: '客户经理',
          txt: '（经理数/人）',
          type: 0,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-265113436Group 1142814542.png'
        },
        {
          name: '走访数据',
          txt: '（走访数/次）',
          type: 2,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-295098173Group 1142814742.png'
        },
        {
          name: '走访需求',
          txt: '（需求数/个）',
          type: 1,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-362526488Group 1142815037.png'
        },
        {
          name: '预算费用',
          txt: '（费用/万元）',
          type: 2,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-397294558Group 1142814504.png'
        },
        {
          name: '案例数据',
          txt: '（案例数/个）',
          type: 1,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-446368351Group 1142815034.png'
        },
        {
          name: '潜在线索',
          txt: '（线索/个）',
          type: 2,
          img: 'https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-468600986Group 1142815038.png'
        }
      ],
      leftList: []
    }
  },
  computed: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {}
}
</script>
      
      <style lang="less" scoped>
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 0.0625rem solid #dcdfe6;
  border-radius: 0.125rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: #fff;
  z-index: 1;
}
::v-deep .el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  margin-right: 1.875rem;
  margin: 10px 0px;
}
.three_cai {
  height: calc(100vh - 130px);
  background: #f6f7fb;
}

.dataTxt {
  .ul_flex {
    width: 100%;
    height: 180px;

    padding: 0;
    display: flex;
    justify-content: space-between;
    .ul_flex_up {
      width: 25%;
      display: flex;
      flex-direction: column;
      .data_info {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 16px;
          font-weight: 550;
          color: #333;
        }

        .numInfo {
          .num {
            font-size: 20px;
            font-weight: 600;
            line-height: 33.6px;

            margin-left: 12px;
          }
          .unit {
            font-size: 14px;
            margin-left: 2px;
          }

          .add {
            font-size: 12px;
            font-weight: 400;
            line-height: 16.8px;
            color: #ff6c66;
          }

          .addNum {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            color: #ff6c66;
            margin-left: 6px;
          }

          .addIcon {
            color: #ff6c66;
            margin-left: 6px;

            i {
              font-size: 17px;
            }
          }
        }

        .numInfo:hover {
          .num {
            color: #448aff;
          }
        }
      }
    }
    .li_center {
      height: 165px;
      padding-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0px 15px 0px 0px;
      border-radius: 4px;
      background: #fff;
      cursor: pointer;
      .data_info {
        display: flex;

        .title {
          font-size: 16px;
          font-weight: 550;
          color: #333;
          margin-left: 20px;
        }

        .numInfo {
          margin-top: 10px;
          .num {
            font-size: 20px;
            font-weight: 600;
            line-height: 33.6px;
          }
          .unit {
            font-size: 14px;
            margin-left: 2px;
          }

          .add {
            font-size: 12px;
            font-weight: 400;
            line-height: 16.8px;
            color: #ff6c66;
          }

          .addNum {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            color: #ff6c66;
            margin-left: 6px;
          }

          .addIcon {
            color: #ff6c66;
            margin-left: 6px;

            i {
              font-size: 17px;
            }
          }
        }

        .numInfo:hover {
          .num {
            color: #448aff;
          }
        }
      }
    }
    .left_margin {
      margin-left: 10px;
    }
    .ul_flex_center {
      display: flex;
      flex-direction: column;
      width: 800px !important;
      margin-right: 15px;
      border-radius: 4px;
      .bottom_flex {
        display: flex;
        justify-content: space-between;
      }
      .li_two {
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        .data_info {
          display: flex;
          align-items: center;
          .title {
            font-size: 16px;
            font-weight: 550;
            color: #333;
          }

          .numInfo {
            .num {
              font-size: 20px;
              font-weight: 600;
              line-height: 33.6px;
            }
            .unit {
              font-size: 14px;
              margin-left: 2px;
            }

            .add {
              font-size: 12px;
              font-weight: 400;
              line-height: 16.8px;
              color: #ff6c66;
            }

            .addNum {
              font-size: 16px;
              font-weight: 400;
              line-height: 22.4px;
              color: #ff6c66;
              margin-left: 6px;
            }

            .addIcon {
              color: #ff6c66;
              margin-left: 6px;

              i {
                font-size: 17px;
              }
            }
          }

          .numInfo:hover {
            .num {
              color: #448aff;
            }
          }
        }
      }
      .li_two_bottom {
        width: 46%;
        height: 75px;
        padding: 0px 0 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        margin-top: 15px;
        .data_info {
          display: flex;

          flex-direction: column;
          .title {
            font-size: 16px;
            font-weight: 550;
            color: #333;
          }

          .numInfo {
            padding-top: 5px;
            .num {
              font-size: 20px;
              font-weight: 600;
              line-height: 33.6px;
            }
            .unit {
              font-size: 14px;
              margin-left: 2px;
              color: #ff6c66;
            }

            .add {
              font-size: 12px;
              font-weight: 400;
              line-height: 16.8px;
              color: #ff6c66;
            }

            .addNum {
              font-size: 16px;
              font-weight: 400;
              line-height: 22.4px;
              color: #ff6c66;
              margin-left: 6px;
            }

            .addIcon {
              color: #ff6c66;
              margin-left: 6px;

              i {
                font-size: 17px;
              }
            }
          }

          .numInfo:hover {
            .num {
              color: #448aff;
            }
          }
        }
      }
    }

    .icon {
      width: 61px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 5px;
      margin-right: 20px;

      i {
        // width: 32px;
        // height: 28px;
        font-size: 32px;
      }
    }

    .icon_img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }

  ul li:nth-child(3) .icon {
    background: #f4b667;
  }

  ul li:nth-child(4) .icon {
    background: #9a7bf2;
  }

  ul li:nth-child(5) .icon {
    background: #609bf0;
  }
}
.center_chart {
  display: flex;
  //     grid-template-columns: repeat(4,1fr);
  //   gap: 15px;
  .center_chart_item {
    width: 24%;
    height: 400px;
    background: #fff;
    margin-right: 16px;
  }
}
.center_chart_bottom {
  margin-top: 15px;
  display: flex;
  .center_chart_bottom_item {
    width: 47%;
    height: 400px;
    padding: 15px;
    border-radius: 4px;
    background: #fff;
    margin-right: 16px;
    .tab_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_title {
        font-weight: bold;
        color: #464646;
      }
      .right_tab {
        display: flex;
        align-items: center;
        .item_date {
          background: #fbfbfb;
          border-radius: 4px;
          margin-right: 5px;
          cursor: pointer;
        }
        .back {
          background: #85b6ff;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }
 
  }
  .data_echarts_ranging {
        flex: 1;
      border-radius: 0.5rem;
      margin-right: 8px;
      background: #fff;
     
      box-sizing: border-box;
      padding: 15px;

      .data_echarts_ranging_top {
    
        display: flex;
        justify-content: space-between;

        .data_echarts_ranging_top_title {
          flex: 1;
          align-items: center;
          display: flex;

          span {
            color: #151515;
            font-weight: 550;
            font-size: 16px;
            margin-right: 5px;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }

        .data_echarts_ranging_top_item {
        
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: flex-end;

          .data_echarts_ranging_top_item_more {
            color: #4e93fb;
            font-size: 14px;
            font-weight: 400;
            margin-right: 20px;
            cursor: pointer;
          }

          .data_echarts_ranging_top_item_date {
            font-size: 12px;
            font-weight: 400;
            line-height: 16.8px;
            color: #333;
            display: flex;

            div {
              display: inline-block;
              width: 27px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 4px;
              cursor: pointer;
            }

            .date_active {
              background: #85b6ff;
              color: #fff;
            }
          }
        }
      }

      .data_echarts_ranging_bottom {
     
        box-sizing: border-box;
        padding: 50px 8px 30px 8px;

        .top_three {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;

          .top_three_item {
            display: flex;
            box-sizing: border-box;
            padding: 0 20px;
            border-radius: 2px;
            flex-direction: column;
            align-items: center;
            position: relative;
            flex: 1;

            .img1 {
              position: absolute;
              left: 50%;
              top: -40px;
            }

            .profile {
              width: 50px;
              height: 50px;
              border-radius: 50px;
              margin-top: -25px;
            }

            .ranging {
              position: absolute;
              top: 10px;
              right: 10px;
            }

            h2 {
              margin: 4px 0 0 0;
              font-weight: 600;
              font-size: 16px;
            }

            .item_list {
              margin-top: 6px;
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;

              .left {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
              }

              .right {
                color: #333333;
                font-size: 12px;
                font-weight: 400;
                width: 80px;
                text-align: center;
                display: inline-block;

                em {
                  color: #4e93fb;
                  font-style: normal;
                }
              }
            }
          }

          .one {
            margin: 0 14px;
            height: 150px;
            background: linear-gradient(180deg, #ffedd0 0%, #fffef8 77.92%);

            h2 {
              margin-bottom: 20px;
            }
          }

          .two {
            margin-top: 10px;
            height: 140px;
            background: linear-gradient(180deg, #e1e1e1 0%, rgba(250, 250, 250, 0.16) 100%);

            h2 {
              margin-bottom: 10px;
            }
          }

          .three {
            margin-top: 20px;
            height: 130px;
            background: linear-gradient(180deg, #ffdecf 0%, rgba(254, 248, 245, 0.2) 100%);
          }
        }
      }
    }

    & > .data_echarts_ranging:last-child {
      margin-right: 0;
      margin-left: 16px;
    }
}
</style>
      