<template>
    <div class="compon_echarts">
      <div class="compon_echarts_enterprise" ref="echarts"></div>
    </div>
  </template>
    
    <script>
  // 引入echarts
  import * as echarts from 'echarts'
  
  export default {
    name: 'ComponEcharts',
    props: {
      companyList: {
        type: Array,
        default: () => []
      }, // 数据
      businessChatParams: {
        type: Object,
        default: () => {}
      }
    },
    watch: {
      companyList: {
        deep: true, // 深度监听数组变化
        handler(newItems, oldItems) {
          const myChart = echarts.init(this.$refs.echarts)
          myChart.dispose()
          this.renderEcharts()
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.renderEcharts()
      })
    },
  
    methods: {
      renderEcharts() {
        const myChart = echarts.init(this.$refs.echarts)
        // 所有的数据
        // console.log(this.businessChatParams);
        let title = ''
        const chartArr = this.companyList
  
        let option
        option = {
          title: {
            top: '3%',
            text: '拓展中的不同阶段的服务商数量',
            left: 'center'
          },
  
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              // 自定义提示框内容
              return `${params.name}: ${params.value} (${params.percent}%)`
            }
          },
          color: ['#f1bb4c', '#afa3f5', '#e084ff', '#e084ff', '#00d488','#3feed4','#3bafff'],
          series: [
            {
              data: [
                { value: 108, name: '微信对接' },
                { value: 735, name: '建群管理' },
                { value: 580, name: '资料上传' },
                { value: 484, name: '协议签署' },
                { value: 300, name: '运营启动' },
                { value: 300, name: '电话沟通' }
              ],
              type: 'pie',
              radius: ['0', '60%'],
              center: ['50%', '50%'],
  
              label: {
                show: true,
                position: 'outside',
  
                formatter: '{b}:{c}' // 标签格式
              }
            },
            {
                data: [
                { value: 1048, name: '微信对接' },
                { value: 735, name: '建群管理' },
                { value: 580, name: '资料上传' },
                { value: 484, name: '协议签署' },
                { value: 300, name: '运营启动' },
                { value: 300, name: '电话沟通' }
              ],
              type: 'pie',
              radius: ['0', '60%'],
              center: ['50%', '50%'],
  
              label: {
                show: true,
                position: 'inside',
                color: '#fff',
                formatter: ' {d}%' // 显示名称、数值和百分比
              }
            }
          ]
        }
        option && myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    }
  }
  </script>
    
    
    <style scoped lang="less">
  .compon_echarts {
    width: 100%;
    overflow: hidden;
  
    .compon_echarts_enterprise {
      height: 440px;
      overflow: hidden;
      width: 100%;
    }
  }
  </style>
    