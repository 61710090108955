<template>
  <div class="map-core" ref="mapCore"></div>
</template>
  
  <script>
import * as echarts from 'echarts'
import china from '../data/100000_full.json'

export default {
  data() {
    //这里存放数据
    return {
      list: [
        { name: '西藏自治区', value: 20057.34 },
        { name: '湖南省', value: 1000 },
        { name: '湖江西省', value: 3000 }
      ],
      mapCore: null
    }
  },
  mounted() {
    //获取地图容器
    this.mapCore = this.$refs.mapCore
    //初始化地图
    if (this.mapCore) {
      this.initMap(this.mapCore)
    }
  },
  methods: {
    // 初始化
    initMap(refName) {
      const myChart = echarts.init(refName)
      echarts.registerMap('china', { geoJSON: china })
      var data = this.list

      let option = {
        title: {
            top: '3%',
            text: '合作服务商的所在区域分布展示',
            left: 'center'
          },
        tooltip: {
          trigger: 'item'
        },
        //   //左侧小导航图标
        //   visualMap: {
        //     show: true,
        //     x: "right",
        //     y: "center",
        //     max: 40000,
        //     min: 0,
        //     realtime: false,
        //     calculable: true,
        //     textStyle: {
        //       color: "#FFFFFF", // 文字颜色
        //       fontSize: 15, // 字体大小
        //     },
        //     inRange: {
        //       color: ["#F4C00E", "#C7D413", "#54F38C", "#4FBAD9"],
        //     },
        //   },
        grid: {
          left: '20%',
          right: '20%',
          top: '20%',
          bottom: '20%'
        },
        geo: {
          map: 'china',
          left: '50px', // 左边距
          right: '50px', // 右边距
          top: '50px', // 顶部距
          bottom: '50px', // 底部距
          aspectScale: 0.75,
          layoutCenter: ['50%', '67%'], //地图位置
          layoutSize: '100%',
          roam: false, //关闭缩放
          label: {
            show: false // 隐藏省份名称
          },
          itemStyle: {
            normal: {
              borderColor: '#51d4b5',
              borderWidth: 0.5,
              color: {
                type: 'linear-gradient',
                x: 0,
                y: 1500,
                x2: 2500,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#4f8fc9' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#4f8fc9' // 100% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#333333'
            }
          },
          //   regions: [
          //     {
          //       name: '南海诸岛',
          //       itemStyle: {
          //         areaColor: 'rgba(0, 10, 52, 1)',
          //         borderColor: 'rgba(0, 10, 52, 1)'
          //       },
          //       emphasis: {
          //         areaColor: 'rgba(0, 10, 52, 1)',
          //         borderColor: 'rgba(0, 10, 52, 1)'
          //       }
          //     }
          //   ],
          z: 2
        },
        series: [
          {
            name: '',
            type: 'map',
            map: 'china',

            label: {
              show: false,
              color: '#FFFFFF',
              fontSize: 10,
              formatter(params) {
                // let name = params.name;
                // const index = data.findIndex((item) => {
                //     return params?.name === item.name
                // });

                // if(index !== -1) {
                //     name = `${params.name}\n${data[index].value}`
                // }

                return params.name
              }
            },
            aspectScale: 0.75,
            layoutCenter: ['50%', '66%'], //地图位置
            layoutSize: '100%',
            roam: false, //关闭缩放
            itemStyle: {
              normal: {
                borderColor: '#b6d0e5',
                borderWidth: 0.8,

                areaColor: {
                  type: 'linear-gradient',
                  x: 0,
                  y: 1200,
                  x2: 1000,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#cee9fd' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#cee9fd' // 0% 处的颜色
                    }
                  ],
                  global: true // 缺省为 false
                }
              },
              emphasis: {
                areaColor: '#4f8fc9',
                label: {
                  show: true,
                  color: '#FFFFFF' // 悬停时标签文字颜色
                }
              }
            },
            zlevel: 1,
            data: data
          }
        ]
      }

      myChart.setOption(option)

      // visualMap事件
      myChart.off('datarangeselected') // 为了不触发两次
      myChart.on('datarangeselected', (e) => {
        this.visualMapDataChange(e)
      })
    },
    visualMapDataChange(e) {
      console.log(e)
    }
  }
}
</script>
  
  <style scoped lang="less">
.map-core {
  height: 400px;
}
</style>
  