<template>
  <div class="compon_echarts">
    <div class="compon_echarts_enterprise" ref="echarts"></div>
  </div>
</template>
    
    <script>
// 引入echarts
import * as echarts from 'echarts'

export default {
  name: 'ComponEcharts',
  props: {
    companyList: {
      type: Array,
      default: () => []
    }, // 数据
    businessChatParams: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    companyList: {
      deep: true, // 深度监听数组变化
      handler(newItems, oldItems) {
        const myChart = echarts.init(this.$refs.echarts)
        myChart.dispose()
        this.renderEcharts()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderEcharts()
    })
  },

  methods: {
    renderEcharts() {
      const myChart = echarts.init(this.$refs.echarts)
      // 所有的数据
      // console.log(this.businessChatParams);
      let title = ''
      const chartArr = this.companyList

      let option
      option = {
        title: {
          top: '3%',
          text: '合作服务商的数智产业领域分布展示',
          left: 'center'
        },

        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            // 自定义提示框内容
            return `${params.name}: ${params.value} (${params.percent}%)`
          }
        },
        color: ['#fc8251', '#5470c6', '#9A60B4', '#ef6567', '#f9c956'],
        legend: {
          orient: 'horizontal',
          left: 'center', // 水平居中
          top: '10%' // 放在底部
        },
        series: [
          {
            data: [
              { value: 1048, name: '威胁情报产业链' },
              { value: 735, name: 'IDC产业链' },
              { value: 580, name: '人工智能产业链' },
              { value: 484, name: '虚拟现实' },
              { value: 300, name: '量子通信产业链' }
            ],
            type: 'pie',
            radius: ['50', '60%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2
            },

            emphasis: {
              label: {
                show: true,
                fontSize: '18',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              formatter: ' {c}' // 显示名称、数值和百分比
            }
          }
        ]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    }
  }
}
</script>
    
    
    <style scoped lang="less">
.compon_echarts {
  width: 100%;
  overflow: hidden;

  .compon_echarts_enterprise {
    height: 440px;
    overflow: hidden;
    width: 100%;
  }
}
</style>
    